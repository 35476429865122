import { DESCRIPTIONS } from '@/constants/descriptions';
import i18n from '@/lang/i18n.js';
export const getScopeCategoryMethodName = (scope, category, pattern) => {
    const scopeText = i18n.locale === 'vi' ? 'Phạm vi' : 'Scope'
    if (scope !== 2) {
        if (category === 16) {
        return `${scopeText} ${scope} > ` + DESCRIPTIONS['scope' + scope]['category' + category]['title'];
        } else if (scope === 4) {
        return `その他 > ` + DESCRIPTIONS['scope' + scope]['category' + category]['title'];
        } else {
            const objDescription = DESCRIPTIONS['scope' + scope]['category' + category];
            let result = `${scopeText} ${scope} > ${category}. ` + objDescription['title'];
            if(objDescription['patternId']) {
                result = `${scopeText} ${scope} > ${category}. ` + objDescription['title'] + ` > ` + objDescription['patternId'][pattern];
            }
            return result;
        }
    } else {
        return `${scopeText} ${scope} > ` + DESCRIPTIONS['scope' + scope]['category' + category]['title'];
    }
}

export const showItemName = (scope, category, method) => {
    if (scope === 3) {
      if (
        (category === 4 && method !== 5) ||
        (category === 9 && method !== 2) ||
        (category === 15 && [1,2,3,4].includes(method)) ||
        (category === 6 && (method === 2 || method === 3)) ||
        (category === 7 && (method === 2 || method === 3))
      ) {
        return 'item_name';
      }
    }
    if(scope === 2) {
      return 'item_name';
    }
    if(scope === 1) {
      if(method !== 2) {
        return 'item_name';
      }
    }
    return 'source';
}
